import {
    TextSection,
    CSSContainer, InterestSectionContainer
} from "./PageElements";

const CSS = (props) => {
  return (
      <>
          <InterestSectionContainer id={"css3"}>
              <CSSContainer>
                  <h1>
                      This is my header
                  </h1>
                  <TextSection>
                      This is CSS
                  </TextSection>
              </CSSContainer>
          </InterestSectionContainer>
      </>
  );
}

export default CSS;