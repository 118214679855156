import {
    NavContainer,
    NavMenu,
    Nav,
    NavLinks,
    NavItem
} from "./NavBarElements";

const NavBar = (props) => {
    const items = []
    let propItems = {"home": {name: "Home"}, ...props.navItems}

    Object.keys(propItems).forEach(function(key,index) {
        const offset = key !== "home" ? 0 : -80;
        items.push(<NavItem key={key + "" + index}>
            <NavLinks
                to={key}
                smooth={true}
                duration={500}
                spy={true}
                offset={offset}
            >
                {propItems[key].name}
            </NavLinks>
        </NavItem>);
    });
    return (
        <>
            <Nav>
              <NavContainer>
                  <NavMenu>
                      {items}
                  </NavMenu>
              </NavContainer>
            </Nav>
        </>
    );
}

export default NavBar;