import {Component} from "react";
import {Link} from "react-router-dom";
import "../css/Header.scss"

class Header extends Component {
    render() {
        return (
            <div id={"header"}>
                <div id={"navBar"}>
                    {/*Hi there, this is where the nav bar will go!*/}
                    <button>About me</button>
                    <button>Home</button>
                    <button>Interests</button>
                </div>
                <div id={"profile"}>
                    <span>Caleb Frey</span>
                    <img src={"./assets/profile-picture.jpg"} alt={"Profile Picture"} id={"profile_picture"}/>
                </div>
            </div>
        )
    }
}

export default Header;