import styled from 'styled-components';
import { Link } from 'react-scroll';
export const SectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: #2b2f3d;
  padding-bottom: 80px;
`;

export const CardContainer = styled(Link)`
  position: relative;
  display: flex;
  /*flex: 1 1 300px;*/
  width: 22vw;
  height: 22vw;
  border: 1px solid grey;
  border-radius: 10px;
  background-color: #343949;
  margin: 15px;
  /*margin-bottom: 40px;*/
  font-size: 5em;
  align-items: center;
  justify-content: center;
  transform: scale(1);
  transition: all 300ms ease;
  
  &:hover {
    filter: brightness(90%);
    transform: translateY(-5px);
  }
`;

export const CardImage = styled.img`
  width: 60%;
  height: auto;
  transition: all 300ms ease;
  
  ${CardContainer}:hover & {
    width: 50%;
    height: auto;
    opacity: 0.2;
  }
`;
export const CardImageWrapper = styled.div`
  
`;


export const CardSubContent = styled.div`
  text-align: left;
  border: 1px white solid;
  border-radius: 10px;
  padding: 10px;
  /*filter: drop-shadow(10px 10px black);*/
  background-color: rgba(255, 0, 0, 0.1);

  position: absolute;
  opacity: 0;
  transform: scale(0);
  font-size: 2rem;
  font-family: monospace;
  transition: all 300ms ease;
  
  ${CardContainer}:hover & {
    color: white;
    opacity: 1;
    transform: scale(1);
  }
`;

