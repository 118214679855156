import React from "react";
import styled from 'styled-components'
import { Link as LinkS } from 'react-scroll';

export const NavContainer = styled.div `
  background-color: rgba(35, 39, 51, 0.6);
  display: flex;
  align-items: center;
  padding: 0 24px;
  border-radius: 2em;
`;
export const Nav = styled.nav`
  //background-color: ${({ scrollNav }) => (scrollNav ? 'red' : 'transparent')};
  //background-color: yellow;
  height: 80px;
  width: 100%;
  //width: 500px;
  margin-top: -80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;
`;
export const NavMenu = styled.ul`
  //list-style: none;
  //background-color: #6e75c9;
  list-style-type: none;
  padding: 0;
  display: flex;
  align-items: center;
  text-align: center;
  //margin-right: -22px;
`;

export const NavItem = styled.li`
  height: 30px;
`;

export const NavLinks = styled(LinkS)`
  //color: rgba(255, 0, 0, 0.94);
  color: white;
  display: flex;
  align-items: center;
  //background-color: rgba(43, 47, 61, 0.45);

  //justify-content: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;

  &:hover {
    border-bottom: 2px solid rgba(252, 89, 89, 0.28);
  }

  &.active {
    border-bottom: 2px solid #fc5959;
  }

`;
