import {Link} from "react-scroll";
import {
    CardContainer,
    CardImageWrapper,
    CardImage,
    CardSubContent
} from './SkillCardElements'

const SkillCard = (props) => {
    const sub_skills = "";


    return (
        <>
            <CardContainer to={props.name} spy={true} smooth={true} offset={0} duration={500}>
                <CardImageWrapper>
                    <CardImage src={"./assets/" + props.name + "-logo.png"} alt={props.name}/>
                </CardImageWrapper>
                <CardSubContent>
                    {props.children}
                </CardSubContent>
            </CardContainer>
        </>
    );
}

export default SkillCard;