import logo from './logo.svg';
import './App.css';
import {Route, Link, BrowserRouter, Router} from "react-router-dom";
import Header from './components/Header';
import AboutMe from './components/AboutMe';
import Interests from "./components/Interests";
import NavBar from "./components/NavBar";

function App() {
  return (
    <div className="App">
        <Header />
        {/*<AboutMe />*/}
        {/*<Interests />*/}
        <Interests />
        {/*<header>*/}
        {/*    hi*/}
        {/*</header>*/}
        {/*<body>*/}
        {/*    there*/}
        {/*</body>*/}
        {/*<footer>*/}
        {/*    baby*/}
        {/*</footer>*/}
        {/*<header className="App-header">*/}
        {/*  hi*/}
        {/*</header>*/}
        {/*<body>*/}
        {/*</body>*/}
    </div>
  );
}

export default App;
