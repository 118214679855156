import {
    TextSection,
    CSSContainer, InterestSectionContainer, PythonContainer
} from "./PageElements";

const Python = (props) => {
    return (
        <>
            <InterestSectionContainer id={"python"}>
                <PythonContainer>
                    <TextSection>
                        This is Python
                    </TextSection>
                </PythonContainer>
            </InterestSectionContainer>
        </>
    );
}

export default Python;