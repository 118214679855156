import SkillCard from './SkillCard'
import { SectionContainer } from './SkillCard/SkillCardElements';

const SkillCardSection = (props) => {
    let cards = [];

    Object.keys(props.languages).forEach(function(key,index) {
        cards.push(<SkillCard key={key + "" + index} id={key +'-card'} name={key} />);
    });
    console.log(cards)
  return (
      <>
          <SectionContainer id={"home"}>
              {cards}
          </SectionContainer>
      </>
  );
};

export default SkillCardSection;