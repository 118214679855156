import {
    TextSection,
    CSSContainer, InterestSectionContainer, JavaContainer
} from "./PageElements";

const Java = (props) => {
    return (
        <>
            <InterestSectionContainer id={"java"}>
                <JavaContainer>
                    <TextSection>
                        This is JAVA section


                    </TextSection>
                </JavaContainer>
            </InterestSectionContainer>
        </>
    );
}

export default Java;