import styled from "styled-components";

export const TextSection = styled.div`
  color: white;
  width: 100px;
  height: 100px;
  font-size: 1rem;
  font-family: Calibri, monospace;
  //font-weight: 600;
  //background-color: #61dafb;
`;
export const InterestSectionContainer = styled.div`
  color: lightgray;
  display: flex;
  //filter: brightness(90%);
  background-color: #272a38;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100vw;
`;
export const CSSContainer = styled(InterestSectionContainer)`
  flex-direction: column;
  background-color: #343949;
`;
export const JavaContainer = styled(InterestSectionContainer)`
  //background-color: #343949;
`;
export const PythonContainer = styled(InterestSectionContainer)`
  background-color: #343949;
`;
export const JavaScriptContainer = styled(InterestSectionContainer)`
  //background-color: #343949;
`;
