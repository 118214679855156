import {
    TextSection,
    CSSContainer, InterestSectionContainer, JavaScriptContainer
} from "./PageElements";

const JavaScript = (props) => {
    return (
        <>
            <InterestSectionContainer id={"javascript"}>
                <JavaScriptContainer>
                    <TextSection>
                        This is JavaScript
                    </TextSection>
                </JavaScriptContainer>
            </InterestSectionContainer>
        </>
    );
}

export default JavaScript;